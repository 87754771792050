<template>
	<header class="header">
		<div class="header-width">
			<section class="header-padding">
				<div class="header-logo">
					<img src="../assets/img/logo_c.png" alt="" />
					<span>Cashy</span>
				</div>
				<div class="header-lang">
					<span @click="changeLang('zh-CN')" :class="{ 'active-lang': isLang === 'zh-CN' }">CN</span>
					<span @click="changeLang('en-ID')" :class="{ 'active-lang': isLang === 'en-ID' }">ID</span>
					<span @click="changeLang('en-US')" :class="{ 'active-lang': isLang === 'en-US' }">EN</span>
				</div>
			</section>
		</div>
	</header>
</template>

<script setup>
import { ref } from 'vue'
const isLang = ref('')
isLang.value = sessionStorage.getItem('lang') || 'zh-CN'
const changeLang = langType => {
	sessionStorage.setItem('lang', langType)
	isLang.value = langType
	window.location.reload()
}
</script>

<style lang="scss" scoped>
.header {
	background: #f05600;
	width: 100%;
	color: rgba(255, 255, 255, 0.3);
	font-weight: bold;

	.header-width {
		font-size: 24px;
		box-sizing: border-box;
		padding: 0 60px;
		max-width: 1440px;
		margin: 0 auto;
	}
	.header-padding {
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding: 25px 0;
		box-sizing: border-box;
		.header-logo {
			display: flex;
			align-items: center;
			cursor: pointer;
			color: #fff;
			> img {
				width: 48px;
				height: 48px;
				margin-right: 10px;
			}
		}
		.header-lang {
			display: flex;
			align-items: center;
			justify-content: space-between;
			span {
				cursor: pointer;
				margin-left: 40px;
			}
			.active-lang {
				color: #fff;
				font-weight: bold;
			}
		}
	}
}
@media all and (max-width: 1440px) {
	.header {
		.header-width {
			font-size: 24px;
			padding: 0 60px;
		}
		.header-padding {
			.header-logo {
				> img {
					width: 48px;
					height: 48px;
					margin-right: 10px;
				}
			}
		}
	}
}

@media all and (max-width: 750px) {
	.header {
		max-width: 750px;
		.header-width {
			width: 100%;
			padding: 0 21px;
		}
		.header-padding {
			padding: 13px 0;
			.header-logo {
				font-size: 22px;
				> img {
					width: 32px;
					height: 32px;
					margin-right: 12px;
				}
			}
			.header-lang {
				span {
					font-size: 19px;
					margin-left: 20px;
				}
			}
		}
	}
}
</style>
