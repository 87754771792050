import { createI18n } from 'vue-i18n'
import US from '../lang/en-US'
import ID from '../lang/en-ID'
import CN from '../lang/zh-CN'


const CreateI18nVue = createI18n
const messages = {
  'en-ID': {...ID},
  'zh-CN': {...CN},
  'en-US': {...US}
}

const langType = sessionStorage.getItem('lang') || 'zh-CN'
const i18n = new CreateI18nVue({
  locale: langType,
  messages
})
export default i18n
