<template>
	<div class="container">
		<webHeaderVue class="webHeaderVue" :style="{ position: scrollTop > 80 ? 'fixed' : 'static' }"></webHeaderVue>
		<webMainVue @contactClick="contactClick"></webMainVue>
		<webFooterVue></webFooterVue>
	</div>
</template>
<script setup>
import webHeaderVue from './components/web-header.vue'
import webMainVue from './components/web-main.vue'
import webFooterVue from './components/web-footer.vue'
import { onMounted, onUnmounted, ref } from 'vue'

const scrollTop = ref(0)
onMounted(() => {
	window.addEventListener('scroll', handleScroll)
})

const handleScroll = () => {
	scrollTop.value = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop
}
const contactClick = () => {
	document.documentElement.scrollTop = 9999
}
onUnmounted(() => {
	window.removeEventListener('scroll', handleScroll)
})
</script>

<style lang="scss" scoped>
.container {
	overflow-x: hidden;
	overflow-y: auto;
	font-family: SF-Pro-Text-Regular;
	.webHeaderVue {
		overflow: hidden;
		top: 0;
		z-index: 99;
	}
}
</style>
