export default {
    cashy: {
        bannerTitle: 'Menerima dan Membayar dengan Lebih Cepat',
        bannerText: 'Dengan Solusi "Single Integration", memungkinkan bisnis anda untuk menerima pembayaran dari Indonesia. Kami merancang layanan kami secara khusus untuk memenuhi kebutuhan pada pasar Indonesia, CASHY adalah solusi bagi kebutuhan penerimaan dan pembayaran anda.',
        contactBtn: 'Kontak Kami',
        answer: 'JAWABAN ATAS KEBUTUHAN TRANSAKSI ONLINE ANDA',
        cashyState: 'CASHY adalah Payment Gateway yang dirancang secara khusus untuk membantu bisnis anda untuk dapat menerima pembayaran dan membayar dengan mudah dan aman. Kami menyediakan layanan "World Class APIs" sehingga dapat membantu binsis anda untuk bertumbuh.',
        integration: 'Kemudahan dalam Integrasi',
        speed: 'Cepat',
        customer: 'Layanan Pelanggan',
        integrationText: 'Mulailah bersama CASHY, mulailah uji coba dengan lingkungan "sandbox" yang telah kami sediakan, dimana anda dapat melakukan uji coba terlebih dahulu untuk merasakan layanan terbaik yang kami tawarkan.',
        speedText: 'Anda dapat "Go Live" dalam waktu kurang dari 1 hari, kemudian dapat memulai transaksi bersama CASHY. Anda dapat meng-akses histori transaksi pada tampilan Dashboard yang ter-sentralisasi, dapatkan laporan rekonsiliasi harian, kemudian semua jenis laporan keuangan yang mudah dipahami.',
        customerText: 'Nikmati layanan kami, nikmasi kesiap sediaan layanan pelanggan kami responsif dan mengerti kebutuhan anda secara personal.',
        products: 'Produk dan Layanan',
        payin: 'Penerimaan Pembayaran',
        payout: 'Pembayaran',
        contact: 'Kontak Kami',
        office: 'Office',
    }
}